@import '../../theme/variables.scss';
@import '../../theme/color_variables.scss';

.MuiTabs-root {
    display: flex;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: calc($leftbar_width - 10px) !important;
    max-height: calc($leftbar_width - 10px) !important;
    height: calc($leftbar_width - 10px) !important;
    background-color: $light_gray !important;
}

.MuiTab-root {
    font-family: 'Poppins', sans-serif !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    min-width: 100px !important;
}
.PrivateTabIndicator-root-1, .PrivateTabIndicator-root-3 {
    display: none;
    visibility: hidden;
}
.MuiTab-textColorPrimary.Mui-selected {
    background-color: $success !important;
    color: $green_bright !important;
}
.MuiButtonBase-root {
    padding-top: 8px !important;
    align-items: flex-start !important;
}