@import '../theme/color_variables.scss';
.shyftbase_nav {
    display: flex;
    height: 100%;
    justify-content: center;
    background-color: $light_gray;
}
.shyftbase_main_nav_item.logo {
    background-color: $success;
    color: $green_light_1;
    fill: $success;
}
/* main navigation bar */
.shyftbase_main_nav_item > .Polaris-Icon {
    height: 15px;
}
.shyftbase_main_nav_item {
    color: $success;
    fill: $success;
}
.shyftbase_main_nav_item.selected {
    background-color: $green_light_1;
    border: 1px solid $green;

    &.toggle {
        background-color: $success;
        color: white;
        fill: white;
        border: 1px solid white;
    }
}
.shyftbase_main_nav {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.shyftbase_main_nav_item.empty {
    background-color: transparent !important;
}
.shyftbase_main_nav_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    font-size: 9px;
    &:hover {
        background-color: $green_light_1;
        fill: $success;
        color: $success;
    }
}
.Polaris-Icon {
    margin: 1px;
}
