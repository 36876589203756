.Custom__Group__Header {
  border-top: 16px solid #e3e3e3;
  border-left: 16px solid #e3e3e3;
  background: #EDEDED !important;
  border-radius: 30px !important;
  outline: 1px solid #ccc;
  outline-offset: -8px ;
}
.Custom__Group__Header td {
  color:  #999;
  font-size: 11px;
}
.Custom__Group__Header + .VueTables__row  {
  border-top: 12px solid #fff;
}
.VueTables__row  {
  border-left: 16px solid #e3e3e3; 
}
.rowNumber {
  width: 1px;
  min-width: 6px;
  max-width: 6px;
}
.rowColorContainer {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  min-width: 6px;
  width: 6px;
  max-width: 6px !important;
  padding: 0px !important;
}
.rowColor {
  height: 30px;
  min-width: 6px;
  width: 6px;
  max-width: 6px;
}
.VueTables__limit, .VueTables__limit-field{
  position: absolute; 
  right: 16px !important; 
  top: 3px; 
  max-width: 250px;
  font-size: 12px;
  z-index: 10000
}
.VuePagination__count  {
  position: absolute; 
  left: 16px !important; 
  top: 8px; 
  max-width: 250px;
  font-size: 12px;
}
.VueTables__table > thead,
.VueTables__table > thead > tr, 
.VueTables__table > thead > tr > th {
  background: #eee !important;
  color: #888 !important;
  font-size: 11px;
  font-weight: 500;
}
.v-popper__popper {
  box-sizing: border-box;
  letter-spacing: normal;
  color: #3d3d3d;
  background-color: #fff;
  max-width: 300px;
  padding: 8px 8px 5px 8px;
  border-radius: 4px;
  border: solid 1px black;
  box-shadow: 0 7px 8px 0 rgba(170, 170, 170, 0.2), 0 5px 22px 4px rgba(170, 170, 170, 0.14),
    0 12px 17px 2px rgba(170, 170, 170, 0.12);
}

.no-scroll {
  overflow-y: hidden !important;
}
.ag-tool-panel-wrapper > * {
  width: 100%;
}
.ag-tool-panel-wrapper {
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  background-color: $green_bright !important;
  text-align: center;
}
.actions-button-cell {
  overflow: visible;
}
.ag-row {
  z-index: 0;
}
.ag-row.ag-row-hover {
  z-index: 1;
}
.orderTable_no_selected_order {
  display: grid;
  grid-template-columns: 100% 0%;
  grid-template-rows: 1fr;
  height: 100%;
}
.orderTable_selected_order {
  display: grid;
  grid-template-columns: 13% 87%;
  grid-template-rows: 1fr;
  height: 100%;
}
.ag-details-row {
  background-color: $yellow_light_2 !important;
}
.ag-cell { text-align: left;} 