/* variables */
/* main colors */
$blue: #1184e8;
$cyan: #429bb8;
$green: #007d6a;
$orange: #de5a35;
$pink: #e117b0;
$purple: #d779d9;
$red: #ed184f;
$yellow: #d9bb4e;
/* bright colors */
$blue_bright: #a3cef3;
$cyan_bright: #b6dae0;
$green_bright: #b2d9d2;
$orange_bright: #e78b71;
$pink_bright: #f3a3e0;
$purple_bright: #e5a9e5;
$red_bright: #f14773;
$yellow_bright: #e5d28a;

/*  light 1 colors*/
$blue_light_1: #d0e6f9;
$cyan_light_1: #daecef;
$green_light_1: #d4eae6;
$orange_light_1: #f1bbaa;
$pink_light_1: #f9d0ef;
$purple_light_1: #f1d0f1;
$red_light_1: #f697af;
$yellow_light_1: #eee2b4;

/* light 2 colors */
$blue_light_2: #e7f2fc;
$cyan_light_2: #ecf5f7;
$green_light_2: #e5f2f0;
$orange_light_2: #f8ded6;
$pink_light_2: #fce7f7;
$purple_light_2: #f7e4f7;
$red_light_2: #fbd5df;
$yellow_light_2: #f8f2de;

/* dark colors */
$blue_dark_1: #0f76d0;
$cyan_dark_1: #347c93;
$green_dark_1: #006454;
$orange_dark_1: #c1431f;
$pink_dark_1: #b4128c;
$purple_dark_1: #965497;
$red_dark_1: #c30f3d;
$yellow_dark_1: #c9a72b;

/* override bulma colors */
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$primary: $green_dark_1;
$link: $blue;

/* Text Color */
/* Form Elements */
$main_form_text_color: #343434;
$secondary_form_text_active: #8c8c8c;
$secondary_form_text_disable: #989898;
/* UI Text */
$main_ui_text_color: #000000;
$secondary_ui_text_active: #8c8c8c;
$secondary_ui_text_disable: #989898;
/* Background Color */
$light_background_color: #fbfbfb;
$light_gray: #E5E5E5;


$tabs-link-hover-color: white;
$tabs-link-active-color: white;
$tabs-link-hover-border-bottom-color: $green;
$tabs-boxed-link-hover-background-color: $green;
$tabs-boxed-link-active-background-color: $green;
$tabs-boxed-link-active-border-color: $green;
$tabs-toggle-link-border-color: $green;
$tabs-toggle-link-hover-background-color: $green;
$tabs-toggle-link-hover-border-color: $green;
$tabs-toggle-link-active-background-color: $green;
$tabs-toggle-link-active-border-color: $green;