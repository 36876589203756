.app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
.a {
    text-decoration: none !important;
}
html.md-theme-default {
    overflow: hidden;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 4px;
}
::-webkit-scrollbar-thumb {
    background: #9b8bca;
    border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}